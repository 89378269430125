<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Jobs Management</h1>
      </v-col>
    </v-row>
    <v-form ref="form"
      lazy-validation>
      <v-card class="mt-5">
        <v-card-title>Create Single Job</v-card-title>
        <v-divider />
        <v-card-text>
          <single :job-info.sync="jobData" />
          <job-detail :job-info.sync="jobData" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <btn-job :job-info.sync="jobData" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    single: () => import('@/components/jobs/singleInfo.vue'),
    JobDetail: () => import('@/components/jobs/jobDetail.vue'),
    BtnJob: () => import('@/components/jobs/jobBottom.vue')
  },
  data: () => ({
    jobData: {
      name: '',
      owner: '',
      totalEstimateIds: 0,
      startTime: 0,
      endTime: 0,
      titleTh: '',
      titleEn: '',
      messageTh: '',
      messageEn: '',
      category: '',
      contentType: '',
      deepLinkLiteTh: '',
      deepLinkLiteEn: '',
      deepLink: '',
      imageIdLiteTh: '',
      imageId: '',
      optionKey: '',
      optionValue: '',
      sound: '',
      delay: 0,
      ids: '',
      msisdn: '',
      maxPushPerJob: 0,
      allowOverridePushMessageTime: false,
      allowOverrideQuotaLimitPerDay: false,
      api: '',
      filterDevice: ''
    }
  }),

  computed: {
    ...mapGetters({
      messageSuccess: 'getSuccess'
    })
  },

  async created() {
    this.clearMessage();
    // eslint-disable-next-line no-undef
    eventBus.$on('create', data => this.create(data));

    // eslint-disable-next-line no-undef
    eventBus.$on('createJobTest', data => this.createJobTest(data));
  },

  beforeDestroy() {
    // eslint-disable-next-line no-undef
    eventBus.$off('create');
    // eslint-disable-next-line no-undef
    eventBus.$off('createJobTest');
  },

  methods: {
    clearMessage() {
      this.$store.dispatch('Clear_Message_Status');
    },

    init() {
      this.jobData.name = '';
      this.jobData.owner = '';
      this.jobData.totalEstimateIds = 0;
      this.jobData.startTime = 0;
      this.jobData.endTime =  0;
      this.jobData.titleTh = '';
      this.jobData.titleEn = '';
      this.jobData.messageTh = '';
      this.jobData.messageEN = '';
      this.jobData.category = '';
      this.jobData.contentType = '';
      this.jobData.deepLinkLiteTh = '';
      this.jobData.deepLinkLiteEn = '';
      this.jobData.deepLink = '';
      this.jobData.imageIdLiteTh = '';
      this.jobData.imageId = '';
      this.jobData.optionKey = '';
      this.jobData.optionValue = '';
      this.jobData.sound = '';
      this.jobData.delay = 0;
      this.jobData.ids ='';
      this.jobData.msisdn='';
      this.jobData.api='';
      this.jobData.maxPushPerJob = 0;
      this.allowOverridePushMessageTime = false;
      this.allowOverrideQuotaLimitPerDay = false;
      this.jobData.filterDevice='';
    },

    async create() {
      this.jobData.totalEstimateIds = this.jobData.ids.length;
      await this.$store.dispatch('jobManagement/CREATE_JOB', this.jobData)
        .then(() => {
          if (this.messageSuccess.trim() === 'Create Job success') {
            this.init();
            this.clearMessage();
            this.$router.push({ name: 'jobs' })
          }
        })
    },
    
    async createJobTest() {
      this.jobData.totalEstimateIds = this.jobData.msisdn.length;
        await this.$store.dispatch('jobManagement/CREATE_JOB_TEST', this.jobData)
          .then(() => {
            if (this.messageSuccess.trim() === 'Create Job success') {
              this.clearMessage();
            }
          })
      }
  },
  

};
</script>
